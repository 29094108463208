.maillistsMain {
  /* box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important; */
  /* padding: 10px; */
}
.maillistTableMain {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
    background-color: white;
    height: 100vh;
    min-width: 400px;
}
.maillistsLoading {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  width: 100%;
}
.MuiTableCell-root.MuiTableCell-head.mailListHeader.MuiTableCell-alignLeft.MuiTableCell-stickyHeader {
  background-color: white !important;
}
button.saveMaillistButton {
  text-transform: inherit;
  border-radius: 2px;
  font-weight: 700;
  color: white;
  border: 1px solid rgba(0, 161, 247, 1);
  background-color: rgba(0, 161, 247, 1);
  margin-top: 32px;
}
button.saveMaillistButton:hover {
  background-color: #1c8fce !important;
  color: white !important;
}
.addMaillistPlus {
  color: rgba(0, 153, 243, 1);
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}
.MuiToolbar-root.MuiToolbar-regular.maillistToolbar.MuiToolbar-gutters {
  padding: 5px !important;
}
.MuiTableRow-root.MuiTableRow-hover.Mui-selected {
  background-color: rgba(0, 171, 247, 1) !important;
  color: white;
}
.maillistCells {
  border-bottom: none !important;
}
.maillistIconCells {
  border-bottom: none !important;
width: 24px;
}
.mailListHeader {
  border-bottom: none !important;
}
div.newMaillistForm {
  padding: 20px 47px;
}
.maillistName .selectedMaillistAddress .maillistGroupName {
  font-size: 14px;
  width: 100%;
}
.MuiFormControl-root.MuiTextField-root.maillistName {
  padding-right: 10px !important;
}
.MuiFormControl-root.MuiTextField-root.maillistGroupName {
  width: 100% !important;
}
.MuiFormControl-root.MuiTextField-root.maillistDomainEmail {
  width: 100% !important;
  color: black !important;
}
.MuiInputBase-root {
  color: rgba(0, 0, 0, 0.54);
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100% !important;
}
.grid-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.grid-container--fit {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
/* 
 .grid-element:after {
  content: "";
  display: flex;
  height: 100%;
  /* vertical-align: bottom; */
/* }  */ */

hr {
  margin: 80px;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  font-size: 14px !important;
  padding-bottom: 9px;
  
}

.wrapper {
  display: grid;
  height: 100vh;
  width: 100%;
  grid-template-rows: 1fr 9fr;
}