.makeStyles-rootSidebar-1 {
  background-color: #ededed !important;
  display: "flex";
  height: 100vh !important;
}
/* .MuiPaper-root {
    background-color: transparent !important;
} */
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(0, 161, 247, 0.15) !important;
}
.MuiListItem-button:hover {
  background-color: rgba(0, 171, 247, 1) !important;
}
.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
  background-color: #ededed !important;
}
button.saveButton {
  text-transform: inherit;
  border-radius: 2px;
  font-weight: 700;
}
button.saveButton:hover {
  color: white;
  background-color: #ededed;
}
.greyBar {
  height: 100vh;
  width: 56px;
  background: url("../assets/greyBar.svg");
}
.logoImgLeftBar {
  height: 56px;
  width: 56px;
}
.langButton {
  position: fixed;
  width: 56px;
  text-align: center;
  bottom: 56px;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.logoutButton {
  width: 56px;
  text-align: center;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.help {
  position: fixed;
  bottom: 0;
}
.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
  font-size: 14px;
}

.langButton:hover {
  background-color: #ffffff47;
  border-radius: 50%;
}
.logoutButton:hover {
  background-color: #ffffff47;
  border-radius: 50%;
}
.MuiDialog-root.changeLangiaguDialog {
  position: fixed;
  z-index: 1300;
  /* right: inherit !important; */
  bottom: 0px;
  /* top: inherit !important; */
  left: 0px;
}
